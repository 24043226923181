body {
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.bg-dark {
    background-color: #212121!important;
}

.card-img {
    width: auto;
    padding: 10px;
}

.navbar {
    padding: 20px;
}

iframe {
    /*height: 80vh;*/
    width: 100%;
    /*margin-bottom: 0px;*/
    width: -webkit-fill-available;
}

.navbar-dark .navbar-nav .nav-link {
    font-weight: bold;
    font-style: normal;
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 1em;
    color: rgba(255, 255, 255, .9);
    padding: 10px 13px;
}

a:hover {
    color: cornflowerblue;
}

.card > a:hover {
    color: blue;
    text-decoration: none;
}

#dashboard > .card:hover {
    background-color: lightgray;
}

.App-logo {
    height: auto;
    max-height: 100px;
    width: 140px;
    max-width: 100%;
}

.typeahead {
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    width: 500px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 500px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    max-height: 350px;
    overflow-y: auto;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

@media only screen and (max-width: 768px) {
    .react-autosuggest__input {
        width: -webkit-fill-available;
        height: auto;
    }

    .react-autosuggest__suggestions-container--open {
        width: auto;
    }

    /*Allow the typeahead to go to the top of screen on mobile so that the dropdown fills the screen with less scrolling.*/
    .typeahead {
        padding-top: 10px;
        min-height: 0;
        align-items: inherit;
    }
}

.card-format{
	height:320px;
	width: 30%;
	float:left;
	margin:10px;
}